import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{`Similar Portfolios`}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-buoy/"
            }}>{`Buoy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/heine-bond-model/"
            }}>{`Heine's Bond Model`}</a></li>
        </ul>
      </Aside>
      <Border mdxType="Border">
        <p>{`This page is a placeholder for an unreleased strategy. Stay tuned for updates.`}</p>
      </Border><br />
      <ul>
        <li parentName="ul">{`Objective: income and capital preservation`}</li>
        <li parentName="ul">{`Type: trend-following`}</li>
        <li parentName="ul">{`Invests in: ETFs tracking bonds`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly`}</li>
        <li parentName="ul">{`Taxation: 80% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $5,000`}</li>
      </ul>
      <p>{`Invest Like a Pro's `}<em parentName="p">{`Commitment`}</em>{` aims to maximize the dependable returns at the 5th percentile. The trend-following strategy achieves its objective through a combination of trend-following and stringent risk-management. The strategy can be used with relatively small accounts and rebalances on a weekly schedule. With these characteristics, the strategy appeals to risk-averse investors seeking to meet their critical financial goals.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules`}</h2>
      <Aside mdxType="Aside">
        <p><a parentName="p" {...{
            "href": "https://www.invest-like-a-pro.com/strategies/safeguard/"
          }}><span parentName="a" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1200px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "129.33333333333331%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAaCAYAAAC3g3x9AAAACXBIWXMAAC4jAAAuIwF4pT92AAAETklEQVRIx+2Pa2yTVRiAT7eWbTCi8RKc4i2SKBpwCky6EKMxQaOOse/r6AbbFBAG0nYta+kuXcdlwDZhDOwusLmR4RisINuAgJJopqBOE4OYaFQS5AcZzDATA+WyfucxX1sUdH+M/PQkT97znjfvc95XfDvNQOeURPqmjuGTVCPHnjbS+VQiR6aa6J0yhoFUA8MzBBdmCIamC4amjc6F6VHEhaJpnHam8YsnjfNlaZz3p/Gzz8xZ/0xOr0lncEM6V98xE9piJtRsJtRpJdRdSGifh1BwCaHgUkIdWYTeTSNUPRNBsxX2WOFILnyaByfy4Ms8+DofThbADwvhzFIYWgR0MdrRCBO+VMW17+chZG0WWoeCdkBFHragHctG67eifZ6D9kUOciAX+U0u8uxbSHkRKUGGY4yAFtaQ10c4PtDHvFovQm5WkJ0qsteC3G9BBi3ITgvyPQtaQEWrV5EBBRnMQf64CTncQfjaEcLaScLyt8h8pwYHSan7CFGxD3F9nYLcpiB3qshdKrJLRe61IPfE6M5G9mQj+3ORvw8gR4ZAngV+BS5z6tw5prT0I6r6SN50FBEqUQj7s9CqFbStCnK7imxXo1Pr4h4L8kMr2nEFbTAA2iWCJ75jSeN+Cro+JqW6j7Fre3m45jApGw8iLpcqjFSqsF6BmixkvQJNCrRHp9V6shk5aoX+HMKfvcyGvnqErxex6n1ESRfC20WcHkv3RPMrnkyuel/ljCeT4QqVcNVcrtQoXNuiMtKkQpsCHRlc3T0Xf+s6xLqDGNb2YKoMYly9j7jK/Rh0yrowLG9C7LUVsMhdzgRXE6n2Wr5yLuCiK5Nh9xyGPK/T78xmY3EhL5bVE+/rIcnbiamoFUNhQ0RgcLZhcLVjWNaIYfFWRELVAUTVIUSVvkYnrznX85Mjgw5bLi84q0iyBRCOnQh7O3G27YgVUQy2HRhWNGMoDETRhcsaEMnVh0muOUTchj5M/iDjvS1M8u/A6NuL8PciVn9AvD8YQej4uhHl3YiS3Qh3RxTPrmh0tiFMb28jwdFIgi3AGHsD49zNCHcbJncrY90tJHpaSXC3YlrZgtG1HaMzhqMJo70Bo70Roy0Wl9YhErNcxGc4iM+wRzDOKcKYYcc0x0FCpjNWc5CU5SJZLb4VS4ybcvHo4jXM8taTvmoLs1bVM921iVcqm3iueDNPLF/PS+UBni/ZSkpBBWMtbsbP85CcHWXcKIgHF/oxu+uYXdEQaXymqJbZ/saI/NmiWlIdNZg9dUx8049prosktZhEZWVEPppU3Gn1cu+CMu7LL4+Qku/jnvml0XuBjwl55ZFc//ihhZU8sng1k5as5a7ckpjUzTjLX4g7rF4eeKMi0qBHHX0avVmPuvR+XZxfHpHruV67e37pn1PejNCt+hp/J1EpHvX9Ru3mlW8RGienY3zy32G6cZ9s/gfCkPIYtxMRN/Fxbif/C/+78A/cf0l3w15GZwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Invest Like a Pro's Safeguard: A managed bond strategy",
                "title": "Invest Like a Pro's Safeguard: A managed bond strategy",
                "src": "/static/589ef713ff51ae424f66ea58fc2c4710/c1b63/book-cover.png",
                "srcSet": ["/static/589ef713ff51ae424f66ea58fc2c4710/5a46d/book-cover.png 300w", "/static/589ef713ff51ae424f66ea58fc2c4710/0a47e/book-cover.png 600w", "/static/589ef713ff51ae424f66ea58fc2c4710/c1b63/book-cover.png 1200w", "/static/589ef713ff51ae424f66ea58fc2c4710/d61c2/book-cover.png 1800w", "/static/589ef713ff51ae424f66ea58fc2c4710/97a96/book-cover.png 2400w", "/static/589ef713ff51ae424f66ea58fc2c4710/742d3/book-cover.png 2550w"],
                "sizes": "(max-width: 1200px) 100vw, 1200px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span></a></p>
        <p><strong parentName="p">{` Source `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.invest-like-a-pro.com/strategies/safeguard/"
            }}>{`Invest Like a Pro: Safeguard`}</a></li>
        </ul>
        <p><strong parentName="p">{` See also `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/robbins-all-seasons/"
            }}>{`All-Seasons Portfolio`}</a></li>
        </ul>
      </Aside>
      <p>{`The operation of `}<em parentName="p">{`Commitment`}</em>{` can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`start with the `}<em parentName="li">{`All-Seasons`}</em>{` asset allocation`}</li>
        <li parentName="ul">{`apply trend-following to all assets`}</li>
        <li parentName="ul">{`scale positions based on the asset's value at risk`}</li>
        <li parentName="ul">{`invest any leftover capital in short-term Treasuries`}</li>
      </ul>
      <p>{`We are big fans of the `}<em parentName="p">{`All-Seasons`}</em>{` portfolio, because of its smooth equity curve and its low drawdowns. By adding a tactical layer to the buy-and-hold portfolio, `}<em parentName="p">{`Commitment`}</em>{` significantly increases absolute and risk-adjusted returns.`}</p>
      <h2>{`Diversification`}</h2>
      <p>{`Just like the `}<em parentName="p">{`All-Seasons`}</em>{` portfolio, `}<em parentName="p">{`Commitment`}</em>{` maintains a high level of diversification across multiple asset classes. The combination of assets makes sure that the strategy can produce positive returns in almost any economic environment.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`Compared to the `}<em parentName="p">{`All-Seasons`}</em>{` portfolio, `}<em parentName="p">{`Commitment`}</em>{` delivers much higher performance at a similar risk level. As a result, `}<em parentName="p">{`Commitment`}</em>{`'s dependable returns at the 5th percentile significantly outperform its passive cousin.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p>{`When the strategy scales its asset exposure, it triggers taxable events. However, outside of major market shifts, the strategy should hold more than 50% of its exposure throughout, effectively reducing the tax load.`}</p>
      <p><em parentName="p">{`Commitment`}</em>{` invests in up to six ETFs at a time. To ensure accurate position sizing, the strategy requires a minimum investment of about $10,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, August 2023`}</a>{`: Initial release.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      